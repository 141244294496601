import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

import { getChapter } from '../../../rest/Querier';
import { useAppContext } from '../../hooks';
import { setChapterScroll, setChapters } from '../../../state/actions';
import { useTranslate } from '../../../translate';

import Chapter from './Chapter';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

function ChapterWrapper() {
  
  const params = useParams();
  const navigate = useNavigate();

  const i18n = useTranslate();
  const { t } = i18n;

  const { state: { mode, chapters, chapterScroll }, dispatch } = useAppContext();

  const [activeKey, setActiveKey] = useState('');

  const createChapterNotFound = () => {
    const chapterNotFound = {
      id: 'not_found',
      title: t('chapter.title.notfound'),
      abstract: t('chapter.abstract.notfound'),
      text: undefined,
      notes: []
    }
    return {
      key: 'not_found',
      children: <Chapter chapter={chapterNotFound} addChapterTab={{}} />,
      label: '',
      closable: false
    };
  }

  useEffect(() => {
    const chapterKey = params.chapter;

    getChapter(chapterKey, mode)
    .then((result) => {
      if(result.status === 'OK') {
        const newChapter = result.data;
        const mappedChapter = {
          key: newChapter.id,
          children: <Chapter chapter={newChapter} addChapterTab={addChapterTab} />,
          label: newChapter.title,
          closable: false
        };
        setActiveKey(chapterKey);
        dispatch(setChapters([mappedChapter]));
      } else {
        const mappedChapter = createChapterNotFound();
        setActiveKey('not_found')
        dispatch(setChapters([mappedChapter]));
      }
    })
    .catch(e => {
      const mappedChapter = createChapterNotFound();
      setActiveKey('not_found')
      dispatch(setChapters([mappedChapter]));
    });

    return () => {
      dispatch(setChapters([]));
      dispatch(setChapterScroll({}));
    }
    
  // eslint-disable-next-line
  }, [mode, dispatch]);

  const goToChapterTab = (currentChapterKey: string, nextChapterKey: string, chapters, chapterScroll) => {
    try {
      chapters.find(chapter => chapter.key === currentChapterKey).closable = true;
      chapters.find(chapter => chapter.key === nextChapterKey).closable = false;
      navigate('../book/chapter/' + nextChapterKey);
      setActiveKey(nextChapterKey);
      dispatch(setChapterScroll({...chapterScroll, [currentChapterKey]: window.scrollY}));
      window.scrollTo(0, chapterScroll[nextChapterKey]);
    } catch (e) {
      console.error(e);
    }
  }

  const addChapterTab = (chapterId: string, activeKey: string, chapters, chapterScroll, mode: string) => {
    const chapter = chapters.find(chapter => chapter.key === chapterId);
    if (!chapter) {
     getChapter(chapterId, mode)
     .then((result) => {
       if(result.status === 'OK') {
         const newChapter = result.data;
         const mappedChapter = {
           key: newChapter.id,
           children: <Chapter chapter={newChapter} addChapterTab={addChapterTab} />,
           label: newChapter.title
         };
         dispatch(setChapters([...chapters, mappedChapter]));
       } else {
         console.error(t('chapter.get.error'));
       }
     })
     .catch(e => {
      console.error(e);
     });
    } else {
     goToChapterTab(activeKey, chapterId, chapters, chapterScroll);
    }
   };

  const removeChapterTab = (targetKey: TargetKey) => {
    const newChapters = chapters.filter((item) => item.key !== targetKey);
    dispatch(setChapters(newChapters)); 
    dispatch(setChapterScroll({...chapterScroll, [targetKey]: 0}));
  };

  const onChapterTabEdit = (
    chapterKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove',
  ) => {
    if (action === 'remove') {
      removeChapterTab(chapterKey);
    }
  };

  const onChapterTabChange = (key: string) => {
    goToChapterTab(activeKey, key, chapters, chapterScroll);
  };

  return (
    <Tabs
        id='chapterTabs'
        hideAdd
        activeKey={activeKey}
        type='editable-card'
        items={chapters}
        onEdit={onChapterTabEdit}
        onChange={onChapterTabChange}
    />
  );
}

export default ChapterWrapper;
