import { useNavigate } from 'react-router-dom';
import { Button, Divider } from 'antd';

import { useTranslate } from '../../../translate';

function Administration() {

  const i18n = useTranslate();
  const { t } = i18n;

  const navigate = useNavigate();

  const logout = () => {
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('access_token');
    navigate('/login');
  };

  return (
    <div id='content'>
      <h1>{t('administration.title')}</h1>
      <div style={{display: 'inline-grid'}}>
        <Divider>{t('divider.chapter')}</Divider>
        <Button onClick={() => navigate('newChapter')} type='primary' style={{marginBottom: 10}}>{t('administration.chapter.create')}</Button>
        <Button onClick={() => navigate('updateChapter')} type='primary' style={{marginBottom: 10}}>{t('administration.chapter.update')}</Button>
        <Button onClick={() => navigate('deleteChapter')} type='primary' style={{marginBottom: 10}}>{t('administration.chapter.delete')}</Button>
        <Divider>{t('divider.community')}</Divider>
        <Button onClick={() => navigate('showStats')} type='primary' style={{marginBottom: 10}}>{t('administration.community.stats')}</Button>
        <Button onClick={() => navigate('listSubscribers')} type='primary' style={{marginBottom: 10}}>{t('administration.community.subscribers')}</Button>
        <Divider>{t('divider.user')}</Divider>
        <Button onClick={() => logout()} type='primary' danger>{t('user.logout')}</Button>
      </div>
    </div>
  )
}

export default Administration;
