import { useEffect, useState } from 'react';
import { Table } from 'antd';

import { getSubscribers } from '../../../rest/Querier';
import { useTranslate } from '../../../translate';


function Subscribers() {

    const i18n = useTranslate();
    const { t } = i18n;

    const [users, setUsers] = useState([]);

    const columns = [
        {
          title: t('subscribers.list.email'),
          dataIndex: 'email',
          key: 'email',
        }
    ];

    useEffect(() => {
        const retrievedUsers = [];
        getSubscribers()
            .then((result) => {
                if (result.status === 'OK') {
                    result.data.forEach((user => {
                        retrievedUsers.push({
                            key: user,
                            email: user
                        })
                    }))
                }
                setUsers(retrievedUsers);
            }).catch(() => setUsers(retrievedUsers));
    }, []);

    return (
        <div id='content'>
            <h1>{t('subscribers.list')}</h1>
            <Table 
                columns={columns} 
                dataSource={users} 
                size='middle'
            />
        </div>
    );
}

export default Subscribers;