import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { AppstoreOutlined, InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { useTranslate } from '../../../translate';

import Introduction from './Introduction';
import TableOfContents from './TableOfContents';
import CardOverview from './CardOverview';

function Book() {
    
  const i18n = useTranslate();
  const { t } = i18n;

  const navigate = useNavigate();
  const params = useParams();

  const tabs = [
    {
        key: 'introduction',
        children: <Introduction />,
        icon: <InfoCircleOutlined />,
        label: t('introduction.title')
    },
    {
        key: 'toc',
        children: <TableOfContents />,
        icon: <UnorderedListOutlined />,
        label: t('tableOfContents.title')
    },
    {
        key: 'cards',
        children: <CardOverview />,
        icon: <AppstoreOutlined />,
        label: t('cards.title')
    },
  ].map(tab => {
    return {
        key: tab.key,
        children: tab.children,
        label: tab.label,
        icon: tab.icon,
    };
  });

  return (
    <Tabs
        id='bookTabs'
        centered
        type='card'
        items={tabs}
        activeKey={params.page}
        onTabClick={(key: string) => navigate('../book/' + key)}
    />
  );
}

export default Book;
