import { useReducer } from 'react';
import { Outlet } from 'react-router-dom';

import reducer, { initialState } from './state/reducer';
import { TranslateProvider } from './translate';

import Context from './context';

import Header from './components/Header';
import Footer from './components/Footer';

function App() {

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <TranslateProvider>
        <Header />
        <Outlet />
        <Footer />
      </TranslateProvider>
    </Context.Provider>
  );
}

export default App;
