import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';

import './index.css';

import App from './App';
import Login from './components/pages/admin/Login';
import Contact from './components/pages/community/Contact';
import Newsletter from './components/pages/community/Newsletter';
import Home from './components/pages/Home';
import PageNotFound from './components/pages/PageNotFound';
import Book from './components/pages/book/Book';
import ChapterWrapper from './components/pages/chapters/ChapterWrapper';
import Administration from './components/pages/admin/Administration';
import ChapterCreation from './components/pages/admin/ChapterCreation';
import ChapterUpdate from './components/pages/admin/ChapterUpdate';
import ChapterRemoval from './components/pages/admin/ChapterRemoval';
import Statistics from './components/pages/admin/Statistics';
import Subscribers from './components/pages/admin/Subscribers';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='' element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='administration' element={<Administration />} />
      <Route path='administration/newChapter' element={<ChapterCreation />} />
      <Route path='administration/updateChapter' element={<ChapterUpdate />} />
      <Route path='administration/deleteChapter' element={<ChapterRemoval />} />
      <Route path='administration/showStats' element={<Statistics />} />
      <Route path='administration/listSubscribers' element={<Subscribers />} />
      <Route path='contact' element={<Contact />} />
      <Route path='newsletter' element={<Newsletter />} />
      <Route path='book/:page' element={<Book />} />
      <Route path='book/chapter/:chapter' element={<ChapterWrapper/>} />
      <Route path='*' element={<PageNotFound />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
);