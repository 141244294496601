import { SET_MODE, SET_CHAPTERS, SET_CHAPTER_SCROLL } from './types';

export const setMode = mode => ({
  type: SET_MODE, mode
});

export const setChapters = chapters => ({
  type: SET_CHAPTERS, chapters
});

export const setChapterScroll = chapterScroll => ({
  type: SET_CHAPTER_SCROLL, chapterScroll
});
