import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertProps, Button, Form, Select, } from 'antd';

import { useTranslate } from '../../../translate';
import { getAllChapterTitles, deleteChapter } from '../../../rest/Querier';

function ChapterRemoval() {

    const i18n = useTranslate();
    const { t } = i18n;

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [result, setResult] = useState('');
    const [chapters, setChapters] = useState([]);

    useEffect(() => {
        getAllChapterTitles()
            .then((result) => {
            if(result.status === 'OK') {
                const chaptersAsOptions = result.data.map(chapter => ({
                    label: chapter.title,
                    value: chapter.id
                }))
                setChapters(chaptersAsOptions)
            }
            });
    }, []);

    const onDeleteChapter = (values) => {
        const chapterPayload = {
            id: values.id
        }
        deleteChapter(chapterPayload)
            .then(result => {
                if(result.status === 'OK') {
                    setResult('chapter.delete.success');
                    setTimeout(function() {
                       navigate('/administration')
                    }, 1000);
                } else {
                    setResult(result.message);
                }
            });
    }

    const showResult = () => {
        if(result) {
            return <Alert 
                message={t(`form.${result}`)} 
                type={result === 'chapter.delete.success' ? 'success' : 'error' as AlertProps['type']} 
                showIcon 
            />;
        }
        return '';
    }

    return (
        <div id='content'>
            <h1>{t('form.chapter.delete')}</h1>
            <Form 
                form={form}
                onFinish={onDeleteChapter}
                className='chapterForm'>
                <Form.Item name='id'>
                    <Select 
                        placeholder={t('form.chapter.delete.select')} 
                        options={chapters}
                        style={{minWidth: 200, width: '50%'}}/>
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit' style={{width: '50%'}}>
                        {t('form.chapter.delete.submit')}
                    </Button>
                </Form.Item>
            </Form>
            {showResult()}
        </div>);
}

export default ChapterRemoval;
