import { SET_MODE, SET_CHAPTERS, SET_CHAPTER_SCROLL } from './types';

export const initialState = {
  mode: '',
  chapters: [],
  chapterScroll: {}
};

const reducer = (state, action) => {
  switch(action.type) {
    case SET_MODE:
      return { ...state, mode: action.mode }
    case SET_CHAPTERS:
      return { ...state, chapters: action.chapters }
    case SET_CHAPTER_SCROLL:
      return { ...state, chapterScroll: action.chapterScroll}
    default:
      return state;
  }
}

export default reducer;
