import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Card from 'antd/es/card/Card';

import { getCards } from '../../../rest/Querier';

function CardOverview() {

  const navigate = useNavigate();

  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    getCards()
      .then((result) => {
        if(result.status === 'OK') {
          setChapters(result.data)
        }
      })
      .catch(() => setChapters([]));
  }, []);

  const showChapters = () => {
    return (
            chapters.map(chapter => 
                <Col key={chapter.id} xs={24} sm={12} md={8} style={{paddingBottom: 10}}>
                  <Card 
                    style={{height: '100%', marginRight: 5, marginLeft: 5}}
                    title={chapter.title} 
                    bordered={true}
                    onClick={() => navigate(`../book/chapter/${chapter.id}`)}
                  >
                      <div className='text'>{chapter.abstract}</div>
                  </Card>
              </Col>
            )
       );
  };

  return (
    <Row>
      {showChapters()}
    </Row>
  );
}

export default CardOverview;
