import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useTranslate } from '../../translate';

function PageNotFound() {

    const navigate = useNavigate();

    const i18n = useTranslate();
    const { t } = i18n;

    return (
        <div id='content'>
            <h1>{t('pageNotFound.title')}</h1>
            <p>
                <Button onClick={() => navigate('/')}>
                    {t('pageNotFound.home')}
                </Button>
            </p>
        </div>
    );
}

export default PageNotFound;
