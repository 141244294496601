import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertProps, Button, Form, Input, Radio, Select, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { useTranslate } from '../../../translate';
import { getChapter, getAllChapterTitles, updateChapter } from '../../../rest/Querier';
import { useAppContext } from '../../hooks';

function ChapterUpdate() {

    const i18n = useTranslate();
    const { t } = i18n;

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { state: { mode } } = useAppContext();

    const [result, setResult] = useState('');
    const [type, setType] = useState('');
    const [chapters, setChapters] = useState([]);

    useEffect(() => {
        getAllChapterTitles()
            .then((result) => {
            if(result.status === 'OK') {
                const chaptersAsOptions = result.data.map(chapter => ({
                    label: chapter.title,
                    value: chapter.id
                }))
                setChapters(chaptersAsOptions)
            }
            });
    }, []);

    const handleSelectChapter = (id: string) => {
        getChapter(id, mode)
            .then(result => {
                if (result.status === 'OK') {
                    const chapter = result.data;
                    setType(chapter.type);
                    form.setFieldsValue({
                        id: chapter.id,
                        title: chapter.title,
                        type: chapter.type,
                        abstract: chapter.abstract,
                        text: chapter.text,
                        notes: chapter.notes,
                        version: chapter.version
                    });
                }
            });
    }

    const onUpdateChapter = (values) => {
        const chapterPayload = {
            id: values.id,
            title: values.title,
            abstract: values.abstract,
            text: values.text,
            type: values.type,
            notes: values.notes,
            version: values.version
        }
        updateChapter(chapterPayload)
            .then(result => {
                if(result.status === 'OK') {
                    setResult('chapter.update.success');
                    setTimeout(function() {
                        navigate('/administration')
                    }, 1000);
                } else {
                    setResult(result.message);
                }
            });
    }

    const showResult = () => {
        if(result) {
            return <Alert 
                message={t(`form.${result}`)} 
                type={result === 'chapter.update.success' ? 'success' : 'error' as AlertProps['type']} 
                showIcon 
            />;
        }
        return '';
    }

    return (
        <div id='content'>
            <h1>{t('form.chapter.update')}</h1>
            <Select
                onChange={handleSelectChapter}
                placeholder={t('form.chapter.update.select')} 
                options={chapters} 
                style={{minWidth: 200}}
                className='responsiveChapterSelect' />
            <Form 
                form={form}
                onFinish={onUpdateChapter}
                labelCol={{ flex: '110px' }}
                labelAlign='left'
                labelWrap
                wrapperCol={{ flex: 1 }}
                style={{display: !type ? 'none' : ''}}
                className='chapterForm'>
                <Form.Item name='id' style={{display: 'none'}}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name='type'
                    label={t('form.chapter.update.type')}
                    rules={[
                        {required: true, message: t('form.chapter.update.typeRequired')}
                    ]}>
                    <Radio.Group disabled>
                        <Radio id='chapter' value='chapter'>{t('form.chapter.update.type.chapter')}</Radio>
                        <Radio id='introduction' value='introduction'>{t('form.chapter.update.type.introduction')}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name='version'
                    label={t('form.chapter.update.version')}
                    rules={[
                        {required: true, message: t('form.chapter.update.versionRequired')}
                    ]}>
                    <Input maxLength={15} />
                </Form.Item>
                <Form.Item
                    name='title'
                    label={t('form.chapter.update.title')}
                    rules={[
                        {required: true, message: t('form.chapter.update.titleRequired')}
                    ]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name='abstract'
                    label={t('form.chapter.update.abstract')}
                    rules={[
                        {required: type === 'chapter', message: t('form.chapter.update.abstractRequired')}
                    ]}
                    style={{display: type === 'introduction' ? 'none': ''}}>
                    <Input.TextArea maxLength={500} />
                </Form.Item>
                <Form.Item
                    name='text'
                    label={t('form.chapter.update.text')}
                    rules={[
                        {required: type === 'introduction', message: t('form.chapter.update.textRequired')}
                    ]}>
                    <Input.TextArea maxLength={65000} />
                </Form.Item>
                <h2 style={{paddingBottom: 8, display: type === 'introduction' ? 'none': ''}}>Notes</h2>
                <span style={{display: type === 'introduction' ? 'none': ''}}>
                    <Form.List name='notes'>
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ marginBottom: 8 }} align='baseline'>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'title']}
                                    label={t('form.chapter.update.note.title')}
                                    rules={[{ required: true, message: t('form.chapter.update.notes.add.titleRequired') }]}
                                    >
                                    <Input maxLength={50}/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'text']}
                                    label={t('form.chapter.update.note.text')}
                                    rules={[{ required: true, message: t('form.chapter.update.notes.add.textRequired') }]}
                                    >
                                    <Input.TextArea maxLength={500} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                            ))}
                            <Form.Item>
                                <Button type='dashed' onClick={() => add()} style={{width: '50%'}} block icon={<PlusOutlined />}>
                                    {t('form.chapter.update.notes.add')}
                                </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                </span>
                <Form.Item>
                    <Button type='primary' htmlType='submit' style={{width: '50%'}}>
                        {t('form.chapter.update.submit')}
                    </Button>
                </Form.Item>
            </Form>
            {showResult()}
        </div>);
}

export default ChapterUpdate;
