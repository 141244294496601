const {serverUrl} = window.env;
const servicesUrl = 'services/v1';

export const checkToken = async(username: string) => {
    const payload = {
        username
    }
    const response = await fetch(`${serverUrl}/${servicesUrl}/user/checkToken`, {
        method: 'POST',
        headers: {
            'Authorization': `Jwt ${window.localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    return await response.json();
};

export const login = async(username: string, password: string) => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/user/login`, {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${btoa(`${username}:${password}`)}`,
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const createChapter = async(payload: {}) => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/new`, {
        method: 'POST',
        headers: {
            'Authorization': `Jwt ${window.localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    return await response.json();
};

export const updateChapter = async(payload: {}) => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/update`, {
        method: 'PUT',
        headers: {
            'Authorization': `Jwt ${window.localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    return await response.json();
};

export const deleteChapter = async(payload: {}) => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/delete`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Jwt ${window.localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    return await response.json();
};

export const getTableOfContents = async () => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/toc`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const getAllChapterTitles = async () => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/titles`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const getCards = async () => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/cards`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const getIntroductionText = async (mode: string) => {
    const params = mode ? '?mode=administration' : '';
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/introduction/get${params}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    });
    return await response.json();
};


export const getChapter = async (id: string, mode: string) => {
    const params = mode ? `?id=${id}&mode=administration` : `?id=${id}`;
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/get${params}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const postSubscription = async(email: string, subscription: boolean) => {
    const payload = {
        email,
        subscription
    }
    const response = await fetch(`${serverUrl}/${servicesUrl}/user/subscribe`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    return await response.json();
};

export const getSubscribers = async() => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/user/subscribers/get`, {
        method: 'GET',
        headers: {
            'Authorization': `Jwt ${window.localStorage.getItem('access_token')}`,
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const getStatistics = async() => {
    const response = await fetch(`${serverUrl}/${servicesUrl}/chapter/statistics/get`, {
        method: 'GET',
        headers: {
            'Authorization': `Jwt ${window.localStorage.getItem('access_token')}`,
            'Accept': 'application/json'
        }
    });
    return await response.json();
};

export const postView = async(page: string, type: string) => {
    const payload = {
        page,
        type
    }
    const response = await fetch(`${serverUrl}/${servicesUrl}/view`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    return await response.json();
}
