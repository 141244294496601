import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertProps, Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useTranslate } from '../../../translate';
import { login, checkToken } from '../../../rest/Querier';

function Login() {

  const i18n = useTranslate();
  const { t } = i18n;

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [result, setResult] = useState(''); 

  useEffect(() => {
    // TOIMPROVE: by calling similar check from each admin page
    const username = window.localStorage.getItem('username');
    const token = window.localStorage.getItem('access_token');
    if (username && token) {
        checkToken(username)
            .then((result) => {
                if(result.status === 'KO') {
                    window.localStorage.removeItem('username');
                    window.localStorage.removeItem('access_token');
                    setResult(result.message)
                } else {
                    navigate('/administration')
                }
            })
    }
  }, [navigate]);

  const onLogin = (values) => {
    login(values.username, values.password)
        .then((result) => {
            if (result.status === 'OK') {
                window.localStorage.setItem('username', values.username);
                window.localStorage.setItem('access_token', result.token);
                setResult('user.login.success');
                setTimeout(function() {
                    navigate('/administration');
                }, 1000);
            } else {
                setResult(result.message);
            }
        });
  }

  const showResult = () => {
    if(result) {
        return <Alert 
            message={t(`form.${result}`)} 
            type={result === 'user.login.success' ? 'success' : 'error' as AlertProps['type']} 
            showIcon 
        />;
    }
    return '';
  }

  return (
    <div id='content'>
        <h1>{t('form.user.login.title')}</h1>
        <Form form={form} onFinish={onLogin} className='responsiveLoginForm'>
            <Form.Item
                name='username'
                rules={[
                    {required: true, message: t('form.user.login.usernameRequired')}
                ]}>
                <Input
                    prefix={<UserOutlined />}
                    placeholder={t('form.user.login.username')}
                    autoComplete='username'
                    maxLength={125} />
            </Form.Item>
            <Form.Item
                name='password'
                rules={[
                    {required: true, message: t('form.user.login.passwordRequired')}
                ]}>
                <Input.Password
                    prefix={<LockOutlined />}
                    placeholder={t('form.user.login.password')}
                    autoComplete='current-password'
                    maxLength={100} />
            </Form.Item>
            <Form.Item>
                <Button type='primary' htmlType='submit'>
                    {t('form.user.login.submit')}
                </Button>
            </Form.Item>
        </Form>
        {showResult()}
    </div>
  );
}

export default Login;
