import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getTableOfContents } from '../../../rest/Querier';

function TableOfContents() {

  const navigate = useNavigate();

  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    getTableOfContents()
      .then((result) => {
        if(result.status === 'OK') {
          setChapters(result.data)
        }
      })
      .catch(() => setChapters([]));
  }, []);

  const showChapters = () => {
    return (
        <ul style={{listStyleType: 'none', paddingInlineStart: 0, textAlign: 'center'}}>
        {
            chapters.map(chapter => 
                <li 
                    key={chapter.id} 
                    className='toc_link' 
                    onClick={() => navigate(`../book/chapter/${chapter.id}`)}
                    >
                    {chapter.title}
                </li>
            )
        }
        </ul>);
  };

  return (
    <div id='content'>
        <div id='text' className='text'>{showChapters()}</div>
    </div>
  );
}

export default TableOfContents;
